import { executeOnSingleOrMultiple, isArray, isInArray, } from "tsparticles-engine";
import { CircleShape } from "./Shapes/Circle/CircleShape";
import { Emitter } from "./Options/Classes/Emitter";
import { Emitters } from "./Emitters";
import { ShapeManager } from "./ShapeManager";
import { SquareShape } from "./Shapes/Square/SquareShape";
class EmittersPlugin {
    constructor(engine) {
        this._engine = engine;
        this.id = "emitters";
    }
    getPlugin(container) {
        return new Emitters(this._engine, container);
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        if (source?.emitters) {
            options.emitters = executeOnSingleOrMultiple(source.emitters, (emitter) => {
                const tmp = new Emitter();
                tmp.load(emitter);
                return tmp;
            });
        }
        const interactivityEmitters = source?.interactivity?.modes?.emitters;
        if (interactivityEmitters) {
            if (isArray(interactivityEmitters)) {
                options.interactivity.modes.emitters = {
                    random: {
                        count: 1,
                        enable: true,
                    },
                    value: interactivityEmitters.map((s) => {
                        const tmp = new Emitter();
                        tmp.load(s);
                        return tmp;
                    }),
                };
            }
            else {
                const emitterMode = interactivityEmitters;
                if (emitterMode.value !== undefined) {
                    if (isArray(emitterMode.value)) {
                        options.interactivity.modes.emitters = {
                            random: {
                                count: emitterMode.random.count ?? 1,
                                enable: emitterMode.random.enable ?? false,
                            },
                            value: emitterMode.value.map((s) => {
                                const tmp = new Emitter();
                                tmp.load(s);
                                return tmp;
                            }),
                        };
                    }
                    else {
                        const tmp = new Emitter();
                        tmp.load(emitterMode.value);
                        options.interactivity.modes.emitters = {
                            random: {
                                count: emitterMode.random.count ?? 1,
                                enable: emitterMode.random.enable ?? false,
                            },
                            value: tmp,
                        };
                    }
                }
                else {
                    const emitterOptions = (options.interactivity.modes.emitters = {
                        random: {
                            count: 1,
                            enable: false,
                        },
                        value: new Emitter(),
                    });
                    emitterOptions.value.load(interactivityEmitters);
                }
            }
        }
    }
    needsPlugin(options) {
        if (!options) {
            return false;
        }
        const emitters = options.emitters;
        return ((isArray(emitters) && !!emitters.length) ||
            emitters !== undefined ||
            (!!options.interactivity?.events?.onClick?.mode &&
                isInArray("emitter", options.interactivity.events.onClick.mode)));
    }
}
export async function loadEmittersPlugin(engine, refresh = true) {
    if (!engine.emitterShapeManager) {
        engine.emitterShapeManager = new ShapeManager(engine);
    }
    if (!engine.addEmitterShape) {
        engine.addEmitterShape = (name, shape) => {
            engine.emitterShapeManager?.addShape(name, shape);
        };
    }
    const plugin = new EmittersPlugin(engine);
    await engine.addPlugin(plugin, refresh);
    engine.addEmitterShape("circle", new CircleShape());
    engine.addEmitterShape("square", new SquareShape());
}
export * from "./EmitterContainer";
export * from "./EmittersEngine";
export * from "./Enums/EmitterClickMode";
export * from "./Enums/EmitterShapeType";
