import { Vector, calcPositionOrRandomFromSizeRanged, deepExtend, getRangeValue, getSize, isPointInside, itemFromSingleOrMultiple, randomInRange, rangeColorToHsl, } from "tsparticles-engine";
import { Emitter } from "./Options/Classes/Emitter";
import { EmitterSize } from "./Options/Classes/EmitterSize";
export class EmitterInstance {
    constructor(engine, emitters, container, options, position) {
        this.emitters = emitters;
        this.container = container;
        this._calcPosition = () => {
            return calcPositionOrRandomFromSizeRanged({
                size: this.container.canvas.size,
                position: this.options.position,
            });
        };
        this._destroy = () => {
            this.emitters.removeEmitter(this);
            this._engine.dispatchEvent("emitterDestroyed", {
                container: this.container,
                data: {
                    emitter: this,
                },
            });
        };
        this._emit = () => {
            if (this._paused) {
                return;
            }
            const quantity = getRangeValue(this.options.rate.quantity);
            this._emitParticles(quantity);
        };
        this._emitParticles = (quantity) => {
            const position = this.getPosition(), size = this.getSize(), singleParticlesOptions = itemFromSingleOrMultiple(this._particlesOptions);
            for (let i = 0; i < quantity; i++) {
                const particlesOptions = deepExtend({}, singleParticlesOptions);
                if (this.spawnColor) {
                    const hslAnimation = this.options.spawnColor?.animation;
                    if (hslAnimation) {
                        this.spawnColor.h = this._setColorAnimation(hslAnimation.h, this.spawnColor.h, 360);
                        this.spawnColor.s = this._setColorAnimation(hslAnimation.s, this.spawnColor.s, 100);
                        this.spawnColor.l = this._setColorAnimation(hslAnimation.l, this.spawnColor.l, 100);
                    }
                    if (!particlesOptions.color) {
                        particlesOptions.color = {
                            value: this.spawnColor,
                        };
                    }
                    else {
                        particlesOptions.color.value = this.spawnColor;
                    }
                }
                if (!position) {
                    return;
                }
                const pPosition = this._shape?.randomPosition(position, size, this.fill) ?? position;
                this.container.particles.addParticle(pPosition, particlesOptions);
            }
        };
        this._prepareToDie = () => {
            if (this._paused) {
                return;
            }
            const duration = this.options.life?.duration !== undefined ? getRangeValue(this.options.life.duration) : undefined;
            if (this.container.retina.reduceFactor &&
                (this._lifeCount > 0 || this._immortal) &&
                duration !== undefined &&
                duration > 0) {
                this._duration = duration * 1000;
            }
        };
        this._setColorAnimation = (animation, initValue, maxValue) => {
            const container = this.container;
            if (!animation.enable) {
                return initValue;
            }
            const colorOffset = randomInRange(animation.offset), delay = getRangeValue(this.options.rate.delay), emitFactor = (1000 * delay) / container.retina.reduceFactor, colorSpeed = getRangeValue(animation.speed ?? 0);
            return (initValue + (colorSpeed * container.fpsLimit) / emitFactor + colorOffset * 3.6) % maxValue;
        };
        this._engine = engine;
        this._currentDuration = 0;
        this._currentEmitDelay = 0;
        this._currentSpawnDelay = 0;
        this._initialPosition = position;
        if (options instanceof Emitter) {
            this.options = options;
        }
        else {
            this.options = new Emitter();
            this.options.load(options);
        }
        this._spawnDelay = (getRangeValue(this.options.life.delay ?? 0) * 1000) / this.container.retina.reduceFactor;
        this.position = this._initialPosition ?? this._calcPosition();
        this.name = this.options.name;
        this._shape = this._engine.emitterShapeManager?.getShape(this.options.shape);
        this.fill = this.options.fill;
        this._firstSpawn = !this.options.life.wait;
        this._startParticlesAdded = false;
        let particlesOptions = deepExtend({}, this.options.particles);
        particlesOptions ??= {};
        particlesOptions.move ??= {};
        particlesOptions.move.direction ??= this.options.direction;
        if (this.options.spawnColor) {
            this.spawnColor = rangeColorToHsl(this.options.spawnColor);
        }
        this._paused = !this.options.autoPlay;
        this._particlesOptions = particlesOptions;
        this.size =
            this.options.size ??
                (() => {
                    const size = new EmitterSize();
                    size.load({
                        height: 0,
                        mode: "percent",
                        width: 0,
                    });
                    return size;
                })();
        this._lifeCount = this.options.life.count ?? -1;
        this._immortal = this._lifeCount <= 0;
        this._engine.dispatchEvent("emitterCreated", {
            container,
            data: {
                emitter: this,
            },
        });
        this.play();
    }
    externalPause() {
        this._paused = true;
        this.pause();
    }
    externalPlay() {
        this._paused = false;
        this.play();
    }
    getPosition() {
        if (this.options.domId) {
            const container = this.container, element = document.getElementById(this.options.domId);
            if (element) {
                const elRect = element.getBoundingClientRect();
                return {
                    x: (elRect.x + elRect.width / 2) * container.retina.pixelRatio,
                    y: (elRect.y + elRect.height / 2) * container.retina.pixelRatio,
                };
            }
        }
        return this.position;
    }
    getSize() {
        const container = this.container;
        if (this.options.domId) {
            const element = document.getElementById(this.options.domId);
            if (element) {
                const elRect = element.getBoundingClientRect();
                return {
                    width: elRect.width * container.retina.pixelRatio,
                    height: elRect.height * container.retina.pixelRatio,
                };
            }
        }
        return getSize(this.size, container.canvas.size);
    }
    pause() {
        if (this._paused) {
            return;
        }
        delete this._emitDelay;
    }
    play() {
        if (this._paused) {
            return;
        }
        if (!(this.container.retina.reduceFactor &&
            (this._lifeCount > 0 || this._immortal || !this.options.life.count) &&
            (this._firstSpawn || this._currentSpawnDelay >= (this._spawnDelay ?? 0)))) {
            return;
        }
        if (this._emitDelay === undefined) {
            const delay = getRangeValue(this.options.rate.delay);
            this._emitDelay = (1000 * delay) / this.container.retina.reduceFactor;
        }
        if (this._lifeCount > 0 || this._immortal) {
            this._prepareToDie();
        }
    }
    resize() {
        const initialPosition = this._initialPosition;
        this.position =
            initialPosition && isPointInside(initialPosition, this.container.canvas.size, Vector.origin)
                ? initialPosition
                : this._calcPosition();
    }
    update(delta) {
        if (this._paused) {
            return;
        }
        if (this._firstSpawn) {
            this._firstSpawn = false;
            this._currentSpawnDelay = this._spawnDelay ?? 0;
            this._currentEmitDelay = this._emitDelay ?? 0;
        }
        if (!this._startParticlesAdded) {
            this._startParticlesAdded = true;
            this._emitParticles(this.options.startCount);
        }
        if (this._duration !== undefined) {
            this._currentDuration += delta.value;
            if (this._currentDuration >= this._duration) {
                this.pause();
                if (this._spawnDelay !== undefined) {
                    delete this._spawnDelay;
                }
                if (!this._immortal) {
                    this._lifeCount--;
                }
                if (this._lifeCount > 0 || this._immortal) {
                    this.position = this._calcPosition();
                    this._spawnDelay =
                        (getRangeValue(this.options.life.delay ?? 0) * 1000) / this.container.retina.reduceFactor;
                }
                else {
                    this._destroy();
                }
                this._currentDuration -= this._duration;
                delete this._duration;
            }
        }
        if (this._spawnDelay !== undefined) {
            this._currentSpawnDelay += delta.value;
            if (this._currentSpawnDelay >= this._spawnDelay) {
                this._engine.dispatchEvent("emitterPlay", {
                    container: this.container,
                });
                this.play();
                this._currentSpawnDelay -= this._currentSpawnDelay;
                delete this._spawnDelay;
            }
        }
        if (this._emitDelay !== undefined) {
            this._currentEmitDelay += delta.value;
            if (this._currentEmitDelay >= this._emitDelay) {
                this._emit();
                this._currentEmitDelay -= this._emitDelay;
            }
        }
    }
}
